<template>
  <woot-modal
    :show.sync="show"
    :on-close="onCancel"
    :show-close="false"
    small-modal
    :custom-style="{
      'margin-top': '3rem',
      'margin-left': '-15rem',
      width: '40rem',
    }"
    :modal-position="{ 'align-items': 'flex-start' }"
  >
    <div class="column content-box">
      <div class="flex-row modal-header">
        <span class="mg-right--smaller" style="cursor: pointer">
          <icons
            name="chevronLeft"
            color="green"
            size="medium"
            @click="onCancel"
          />
        </span>
        <span class="subtitle-s2 text-dark">Edit Customer Information</span>
      </div>
      <form class="settings-form" @submit.prevent="onSubmit">
        <account-pii-mask-wrapper
          :pii-data="phoneNumber"
          :mask-type="maskType.PHONE_INPUT"
          :unmask-payload="unmaskRequestPayload"
          :on-unmask="data => handleUnmask('phone_number', data)"
          unmask-action="piiMask/logUnmaskEvent"
          @update="phoneNumber = $event"
        >
          <template v-slot="{ data, isMasked, updateData }">
            <woot-input
              :value="data"
              class="medium-11 columns content-margin"
              :label="$t('EDIT_CONTACT.FORM.PHONE_NUMBER.LABEL')"
              :class="{ error: $v.phoneNumber.$error }"
              :has-error="$v.phoneNumber.$error"
              :error="$t('EDIT_CONTACT.FORM.PHONE_NUMBER.ERROR_MESSAGE')"
              size="large"
              :placeholder="$t('EDIT_CONTACT.FORM.PHONE_NUMBER.PLACEHOLDER')"
              :disabled="isMasked"
              @input="updateData"
              @blur="$v.phoneNumber.$touch"
            />
          </template>
        </account-pii-mask-wrapper>
        <account-pii-mask-wrapper
          :pii-data="email"
          :mask-type="maskType.EMAIL_INPUT"
          unmask-action="piiMask/logUnmaskEvent"
          :on-unmask="data => handleUnmask('email', data)"
          :unmask-payload="unmaskRequestPayload"
          @update="email = $event"
        >
          <template v-slot="{ data, isMasked, updateData }">
            <woot-input
              :value="data"
              :class="{ error: $v.email.$error }"
              :has-error="$v.email.$error"
              size="large"
              :error="$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.ERROR_MESSAGE')"
              class="medium-11 columns content-margin"
              :label="$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.LABEL')"
              :placeholder="$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.PLACEHOLDER')"
              :disabled="isMasked"
              @input="updateData"
              @blur="$v.email.$touch"
            />
          </template>
        </account-pii-mask-wrapper>

        <label class="formlabel">
          <span
            class="title-h5 text-dark"
            v-text="$t('EDIT_CONTACT.FORM.INSTAGRAM')"
          />
        </label>
        <div class="input-group">
          <span class="input-group-label body-b2 text-dark">{{
            `https://instagram.com/`
          }}</span>
          <account-pii-mask-wrapper
            :pii-data="socialProfileUserNames.instagram_username || ''"
            :mask-type="maskType.INSTAGRAM_INPUT"
            unmask-action="piiMask/logUnmaskEvent"
            :on-unmask="data => handleUnmask('instagram_username', data)"
            :unmask-payload="unmaskRequestPayload"
            @update="socialProfileUserNames.instagram_username = $event"
          >
            <template v-slot="{ data, isMasked, updateData }">
              <input
                :value="data"
                class="input-group-field form-input body-b2 text-dark"
                :placeholder="$t('EDIT_CONTACT.FORM.INSTAGRAM')"
                :disabled="isMasked"
                @input="event => updateData(event.target.value)"
              />
            </template>
          </account-pii-mask-wrapper>
        </div>

        <label class="formlabel">
          <span
            class="title-h5 text-dark"
            v-text="$t('EDIT_CONTACT.FORM.FACEBOOK')"
          />
        </label>
        <div class="input-group">
          <span class="input-group-label body-b2 text-dark">{{
            `https://facebook.com/`
          }}</span>
          <account-pii-mask-wrapper
            :pii-data="socialProfileUserNames.facebook || ''"
            :mask-type="maskType.FACEBOOK_INPUT"
            unmask-action="piiMask/logUnmaskEvent"
            :on-unmask="data => handleUnmask('facebook', data)"
            :unmask-payload="unmaskRequestPayload"
            @update="socialProfileUserNames.facebook = $event"
          >
            <template v-slot="{ data, isMasked, updateData }">
              <input
                :value="data"
                class="input-group-field form-input body-b2 text-dark"
                :placeholder="$t('EDIT_CONTACT.FORM.FACEBOOK')"
                :disabled="isMasked"
                @input="event => updateData(event.target.value)"
              />
            </template>
          </account-pii-mask-wrapper>
        </div>
        <woot-input
          v-if="contact.identifier"
          v-model.trim="identifier"
          size="large"
          class="medium-12 columns content-margin disable-social-input"
          :label="$t('EDIT_CONTACT.FORM.IDENTIFIER.LABEL')"
          :placeholder="$t('EDIT_CONTACT.FORM.IDENTIFIER.PLACEHOLDER')"
        />
        <div class="modal-footer">
          <woot-primary-button
            :loading="uiFlags.isUpdating"
            name="Save and close"
            full-width
            :custom-style="{ margin: '0rem 6rem' }"
          />
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { DuplicateContactException } from 'shared/helpers/CustomErrors';
import { required, email } from 'vuelidate/lib/validators';
import customValidations from '../../../routes/dashboard/settings/inbox/validations';
import { mapGetters } from 'vuex';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';
import AccountPiiMaskWrapper from '../piiMask/AccountPiiMaskWrapper.vue';
import { MASK_TYPES } from '../piiMask/constants';
import { getChangedValues, updateNestedObject } from '../../../helper/commons';
import { unmaskFields } from './helpers/piiMask';

export default {
  components: { AccountPiiMaskWrapper },
  mixins: [alertMixin, googleAnalyticsMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hasADuplicateContact: false,
      duplicateContact: {},
      email: '',
      identifier: '',
      name: '',
      phoneNumber: '',
      instagram_username: '',
      facebook: '',
      socialProfileUserNames: {
        instagram_username: '',
        facebook: '',
      },
      unmaskedFormData: {
        email: null,
        phone_number: null,
        instagram_username: null,
        additional_attributes: {
          social_profiles: {
            instagram_username: null,
            facebook: null,
          },
        },
      },
    };
  },
  validations: {
    name: {
      required,
    },
    description: {},
    email: { email },
    companyName: {},
    phoneNumber: { ...customValidations.phone },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
    maskType() {
      return MASK_TYPES;
    },
    unmaskRequestPayload() {
      const payload = {
        user_id: this.currentUser.id,
        resource_id: this.contact.id,
        resource_type: 'Contact',
      };

      return payload;
    },
  },
  watch: {
    contact() {
      this.setContactObject();
    },
  },
  mounted() {
    this.setContactObject();
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    handleUnmask(field, unmaskedData) {
      if (field === 'instagram_username') {
        this.updateUnmaskedData(field, unmaskedData);
        this.updateUnmaskedData(
          'additional_attributes.social_profiles.instagram_username',
          unmaskedData
        );
      } else if (field === 'facebook') {
        this.updateUnmaskedData(
          'additional_attributes.social_profiles.facebook',
          unmaskedData
        );
      } else {
        this.updateUnmaskedData(field, unmaskedData);
      }
    },
    updateUnmaskedData(path, value) {
      updateNestedObject(this.unmaskedFormData, path, value);
    },
    setContactObject() {
      const {
        email: contactEmail,
        phone_number: phoneNumber,
        name,
      } = this.contact;
      const additionalAttributes = this.contact.additional_attributes || {};
      this.name = name || '';
      this.email = contactEmail || '';
      this.identifier = this.contact.identifier || '';
      this.phoneNumber = phoneNumber || '';
      const { social_profiles: socialProfiles = {} } = additionalAttributes;
      this.socialProfileUserNames = {
        instagram_username: this.contact.instagram_username,
        facebook: socialProfiles.facebook || '',
      };
    },
    getContactObject() {
      return {
        id: this.contact.id,
        name: this.name,
        email: this.email,
        identifier: this.identifier,
        phone_number: this.phoneNumber,
        instagram_username: this.socialProfileUserNames.instagram_username,
        additional_attributes: {
          ...this.contact.additional_attributes,
          social_profiles: this.socialProfileUserNames,
        },
      };
    },
    resetDuplicate() {
      this.hasADuplicateContact = false;
      this.duplicateContact = {};
    },
    async getCart() {
      let { phone_number: phoneNumber, _email, name } = await unmaskFields(
        this.unmaskedFormData,
        ['phone', 'email', 'name'],
        this.endPoint,
        this.unmaskRequestPayload,
        this
      );

      if (!phoneNumber && !_email) {
        this.$store.dispatch('emptyCustomerCart');
        this.$store.dispatch('clearCustomer');
        return;
      }

      const payload = {
        phone_number: phoneNumber || '',
        email: _email || '',
        firstName: name || '',
      };
      try {
        this.$store.dispatch('getCustomerCart', payload).then(response => {
          this.$store.dispatch('getCustomer', {
            customerId: response.data.customer,
          });
        });
      } catch (error) {
        // handle error
      }
    },
    async onSubmit() {
      this.googleAnalyticsEvent(
        types.default.CONTACT_EDITED,
        types.default.CONTACT_INFO_OR_PREVIOUS_CONVO,
        types.default.CONTACT_INFO_OR_PREVIOUS_CONVO
      );
      this.resetDuplicate();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('EDIT_CONTACT.FORM_ERROR'), 'error');
        return;
      }

      const payload = getChangedValues(
        this.getContactObject(),
        this.unmaskedFormData
      );

      try {
        await this.$store.dispatch('contacts/update', payload).then(() => {
          this.$store.dispatch('resetDraftOrder');
          this.$store.dispatch('setSelectedAddress', {
            addressType: 'shipping',
            addressId: -1,
          });
          this.getCart();
          this.showAlert(this.$t('EDIT_CONTACT.SUCCESS_MESSAGE'), 'success');
        });
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          this.hasADuplicateContact = true;
          this.duplicateContact = error.data;
          this.showAlert(this.$t('EDIT_CONTACT.CONTACT_ALREADY_EXIST'), 'info');
        } else {
          this.showAlert(this.$t('EDIT_CONTACT.ERROR_MESSAGE'), 'error');
        }
      }
      this.onCancel();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-form {
  padding: $space-normal;
}

.modal-header {
  padding: $space-normal;
  padding-bottom: $zero;
}

.input-group {
  margin-bottom: $space-medium;
}
.input-group-label {
  width: $space-normal * 10;
}
.input-group-field {
  padding: 1.1rem $space-slab;
}
::placeholder {
  color: $text-light;
  opacity: 1;
}
.content-margin {
  margin-bottom: $space-medium;
}

.disable-social-input {
  pointer-events: none;
}
</style>
