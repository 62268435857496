<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="`${$t('EDIT_CONTACT.TITLE')}`"
        :header-content="$t('EDIT_CONTACT.DESC')"
      />
      <form class="row settings-form" @submit.prevent="onSubmit">
        <div class="medium-12 columns">
          <account-pii-mask-wrapper
            :pii-data="name"
            :mask-type="maskType.NAME_INPUT"
            :unmask-payload="unmaskRequestPayload"
            :on-unmask="data => handleUnmask('name', data)"
            unmask-action="piiMask/logUnmaskEvent"
            @update="name = $event"
          >
            <template v-slot="{ data, isMasked, updateData }">
              <woot-input
                :value="data"
                :class="{ error: $v.name.$error }"
                :label="$t('EDIT_CONTACT.FORM.NAME.LABEL')"
                :placeholder="$t('EDIT_CONTACT.FORM.NAME.PLACEHOLDER')"
                :disabled="isMasked"
                class="medium-11 columns content-margin"
                @input="
                  value => handleInputChange(value, updateData, $v.name.touch)
                "
              />
            </template>
          </account-pii-mask-wrapper>
        </div>

        <div class="medium-12 columns">
          <account-pii-mask-wrapper
            :pii-data="email"
            :mask-type="maskType.EMAIL_INPUT"
            :unmask-payload="unmaskRequestPayload"
            :on-unmask="data => handleUnmask('email', data)"
            unmask-action="piiMask/logUnmaskEvent"
            @update="email = $event"
          >
            <template v-slot="{ data, isMasked, updateData }">
              <woot-input
                :value="data"
                :class="{ error: $v.email.$error }"
                :has-error="$v.email.$error"
                class="medium-11 columns content-margin"
                :error="$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.ERROR_MESSAGE')"
                :label="$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.LABEL')"
                :placeholder="$t('EDIT_CONTACT.FORM.EMAIL_ADDRESS.PLACEHOLDER')"
                :disabled="isMasked"
                @blur="$v.email.$touch"
                @input="value => handleInputChange(value, updateData)"
              />
            </template>
          </account-pii-mask-wrapper>
        </div>

        <div class="columns medium-12 content-margin">
          <woot-input
            v-model.trim="description"
            class=""
            rows="3"
            type="text"
            :label="$t('EDIT_CONTACT.FORM.BIO.LABEL')"
            :placeholder="$t('EDIT_CONTACT.FORM.BIO.PLACEHOLDER')"
            @input="$v.description.$touch"
          />
        </div>
        <div style="width: 100%">
          <div class="row medium-12 flex-space-between">
            <div class="medium-5">
              <account-pii-mask-wrapper
                :pii-data="phoneNumber"
                :mask-type="maskType.PHONE_INPUT"
                :unmask-payload="unmaskRequestPayload"
                :on-unmask="data => handleUnmask('phoneNumber', data)"
                unmask-action="piiMask/logUnmaskEvent"
                @update="phoneNumber = $event"
              >
                <template v-slot="{ data, isMasked, updateData }">
                  <woot-input
                    :value="data"
                    :class="{ error: $v.name.$error }"
                    :error="null"
                    :label="$t('EDIT_CONTACT.FORM.PHONE_NUMBER.LABEL')"
                    :placeholder="
                      $t('EDIT_CONTACT.FORM.PHONE_NUMBER.PLACEHOLDER')
                    "
                    :disabled="isMasked"
                    class="medium-12 columns content-margin"
                    @blur="$v.phoneNumber.$touch"
                    @input="value => handleInputChange(value, updateData)"
                  />
                </template>
              </account-pii-mask-wrapper>
            </div>

            <woot-input
              v-model.trim="companyName"
              class="medium-5 columns content-margin"
              :label="$t('EDIT_CONTACT.FORM.COMPANY_NAME.LABEL')"
              :placeholder="$t('EDIT_CONTACT.FORM.COMPANY_NAME.PLACEHOLDER')"
              @input="$v.companyName.$touch"
            />
          </div>
          <span v-if="$v.phoneNumber.$error" class="capital_c3 desc-error">
            {{ this.$t('EDIT_CONTACT.FORM.PHONE_NUMBER.ERROR_MESSAGE') }}
          </span>
        </div>
        <woot-input
          v-if="contact.identifier"
          v-model.trim="identifier"
          class="medium-12 columns content-margin disable-social-input"
          :label="$t('EDIT_CONTACT.FORM.IDENTIFIER.LABEL')"
          :placeholder="$t('EDIT_CONTACT.FORM.IDENTIFIER.PLACEHOLDER')"
        />
        <div class="medium-12 columns">
          <label class="form-label">
            Social Information
          </label>
          <div
            v-for="socialProfile in socialProfileKeys"
            :key="socialProfile.key"
          >
            <div
              class="input-group"
              :class="{
                'disable-social-input':
                  socialProfile.key === 'instagram_username' &&
                  contact.instagram_username !== null,
              }"
            >
              <span class="input-group-label font-size-mini">{{
                socialProfile.prefixURL
              }}</span>

              <account-pii-mask-wrapper
                class="input-group-field"
                :pii-data="socialProfileUserNames[socialProfile.key]"
                :mask-type="getSocialMediaMaskType(socialProfile.key)"
                :unmask-payload="unmaskRequestPayload"
                :on-unmask="data => handleUnmask(socialProfile.key, data)"
                unmask-action="piiMask/logUnmaskEvent"
                @update="socialProfileUserNames[socialProfile.key] = $event"
              >
                <template v-slot="{ data, isMasked, updateData }">
                  <input
                    :value="data"
                    class="input-group-field font-size-mini"
                    :class="{ 'disable-social-input': isMasked }"
                    type="text"
                    :disabled="isMasked"
                    @input="event => updateData(event.target.value)"
                  />
                </template>
              </account-pii-mask-wrapper>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="button clear alert" @click.prevent="onCancel">
            {{ $t('EDIT_CONTACT.FORM.CANCEL') }}
          </button>
          <woot-primary-button
            :loading="uiFlags.isUpdating"
            :name="$t('EDIT_CONTACT.FORM.SUBMIT')"
            front-icon="peopleFilled"
          />
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { DuplicateContactException } from 'shared/helpers/CustomErrors';
import { required, email } from 'vuelidate/lib/validators';
import customValidations from '../../settings/inbox/validations';
import { mapGetters } from 'vuex';
import googleAnalyticsMixin from 'shared/mixins/googleAnalyticsMixin';
import mixPanelAnalyticsMixin from 'shared/mixins/mixPanelAnalyticsMixin';
import * as types from 'shared/constants/googleEventType';
import AccountPiiMaskWrapper from 'dashboard/components/widgets/piiMask/AccountPiiMaskWrapper.vue';
import { MASK_TYPES } from 'dashboard/components/widgets/piiMask/constants';
import { getChangedValues, updateNestedObject } from 'dashboard/helper/commons';
import { unmaskFields } from 'dashboard/components/widgets/conversation/helpers/piiMask';

export default {
  components: { AccountPiiMaskWrapper },
  mixins: [alertMixin, googleAnalyticsMixin, mixPanelAnalyticsMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    parentComponentName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasADuplicateContact: false,
      duplicateContact: {},
      companyName: '',
      description: '',
      email: '',
      identifier: '',
      name: '',
      phoneNumber: '',
      socialProfileUserNames: {
        instagram_username: '',
        facebook: '',
        twitter: '',
        linkedin: '',
      },
      unmaskedFormData: {
        email: null,
        phone_number: null,
        instagram_username: null,
        additional_attributes: {
          company_name: null,
          description: null,
          social_profiles: {
            instagram_username: null,
            facebook: null,
            twitter: null,
            linkedin: null,
          },
        },
      },
      socialProfileKeys: [
        {
          key: 'instagram_username',
          prefixURL: 'https://instagram.com/',
          editable: true,
        },
        {
          key: 'facebook',
          prefixURL: 'https://facebook.com/',
          editable: true,
        },
        {
          key: 'twitter',
          prefixURL: 'https://twitter.com/',
          editable: true,
        },
        {
          key: 'linkedin',
          prefixURL: 'https://linkedin.com/',
          editable: true,
        },
      ],
    };
  },
  validations: {
    name: {
      required,
    },
    description: {},
    email: { email },
    companyName: {},
    phoneNumber: { ...customValidations.postiveNumber },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
    maskType() {
      return MASK_TYPES;
    },
    unmaskRequestPayload() {
      return {
        user_id: this.currentUser.id,
        resource_id: this.contact.id,
        resource_type: 'Contact',
      };
    },
  },
  watch: {
    contact() {
      this.setContactObject();
    },
  },
  mounted() {
    this.setContactObject();
  },
  methods: {
    getSocialMediaMaskType(socialProfile) {
      if (socialProfile.includes('instagram')) {
        return MASK_TYPES.INSTAGRAM_INPUT;
      }
      if (socialProfile.includes('facebook')) {
        return MASK_TYPES.FACEBOOK_INPUT;
      }
      if (socialProfile.includes('linkedin')) {
        return MASK_TYPES.LINKEDIN_INPUT;
      }
      if (socialProfile.includes('twitter')) {
        return MASK_TYPES.TWITTER_INPUT;
      }
      return MASK_TYPES.OTHER;
    },
    getCart() {
      const { phone_number: phoneNumber, _email, name } = this.contact;
      if (!phoneNumber && !_email) {
        this.$store.dispatch('emptyCustomerCart');
        this.$store.dispatch('clearCustomer');
        return;
      }
      const payload = {
        phone_number: phoneNumber || '',
        email: _email || '',
        firstName: name || '',
      };
      try {
        this.$store.dispatch('getCustomerCart', payload);
      } catch (error) {
        // handle error
      }
    },
    onCancel() {
      if (this.parentComponentName === 'ContactInfo') {
        this.mix_panel_clicked_contact_edit_cancel();
      }
      this.$emit('cancel');
    },
    async setContactObject() {
      let {
        phone_number: phoneNumber,
        email: contactEmail,
        name,
      } = await unmaskFields(
        this.unmaskedFormData,
        ['phone', 'email', 'name'],
        this.endPoint,
        this.unmaskRequestPayload,
        this
      );
      const additionalAttributes = this.contact.additional_attributes || {};

      this.name = name || '';
      this.email = contactEmail || '';
      this.identifier = this.contact.identifier || '';
      this.phoneNumber = phoneNumber || '';
      this.companyName = additionalAttributes.company_name || '';
      this.description = additionalAttributes.description || '';
      const {
        social_profiles: socialProfiles = {},
        screen_name: twitterScreenName,
      } = additionalAttributes;
      this.socialProfileUserNames = {
        instagram_username: this.contact.instagram_username,
        twitter: socialProfiles.twitter || twitterScreenName || '',
        facebook: socialProfiles.facebook || '',
        linkedin: socialProfiles.linkedin || '',
      };
    },
    getContactObject() {
      return {
        id: this.contact.id,
        name: this.name,
        email: this.email,
        identifier: this.identifier,
        instagram_username: this.socialProfileUserNames.instagram_username,
        phone_number: this.phoneNumber,
        additional_attributes: {
          ...this.contact.additional_attributes,
          description: this.description,
          company_name: this.companyName,
          social_profiles: this.socialProfileUserNames,
        },
      };
    },
    resetDuplicate() {
      this.hasADuplicateContact = false;
      this.duplicateContact = {};
    },
    async onSubmit() {
      this.googleAnalyticsEvent(
        types.default.CONTACT_EDITED,
        types.default.CONTACT_INFO_OR_PREVIOUS_CONVO,
        types.default.CONTACT_INFO_OR_PREVIOUS_CONVO
      );
      this.resetDuplicate();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('EDIT_CONTACT.FORM_ERROR'), 'error');
        return;
      }
      try {
        const payload = getChangedValues(
          this.getContactObject(),
          this.unmaskedFormData
        );

        await this.$store.dispatch('contacts/update', payload).then(() => {
          this.showAlert(this.$t('EDIT_CONTACT.SUCCESS_MESSAGE'), 'success');
          this.$store.dispatch('resetDraftOrder');
          this.getCart();
          if (this.parentComponentName === 'ContactInfo') {
            this.mix_panel_clicked_contact_edit_submit();
          }
        });
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          this.hasADuplicateContact = true;
          this.duplicateContact = error.data;
          this.showAlert(this.$t('EDIT_CONTACT.CONTACT_ALREADY_EXIST'), 'info');
        } else {
          this.showAlert(this.$t('EDIT_CONTACT.ERROR_MESSAGE'), 'error');
        }
      }
    },
    handleUnmask(field, unmaskedData) {
      if (field === 'instagram_username') {
        this.updateUnmaskedData(field, unmaskedData);
        this.updateUnmaskedData(
          'additional_attributes.social_profiles.instagram_username',
          unmaskedData
        );
      } else if (field === 'facebook') {
        this.updateUnmaskedData(
          'additional_attributes.social_profiles.facebook',
          unmaskedData
        );
      } else if (field === 'twitter') {
        this.updateUnmaskedData(
          'additional_attributes.social_profiles.twitter',
          unmaskedData
        );
      } else if (field === 'linkedin') {
        this.updateUnmaskedData(
          'additional_attributes.social_profiles.linkedin',
          unmaskedData
        );
      } else {
        this.updateUnmaskedData(field, unmaskedData);
      }
    },
    updateUnmaskedData(path, value) {
      updateNestedObject(this.unmaskedFormData, path, value);
    },
    handleInputChange(value, updateData, validate = () => {}) {
      updateData(value);
      validate(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.settings-form {
  padding: $space-slab $space-medium;
}

.input-group-label {
  width: $space-normal * 10;
}
.content-margin {
  margin-bottom: 2.4rem;
}
.form-label {
  padding: $zero $space-small $space-one;
  color: $neutral-grey-800;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
  line-height: 22px;
}
.disable-social-input {
  pointer-events: none;
  color: $text-light;
}

.font-size-mini {
  font-size: $font-size-mini;
}
</style>
