<template>
  <div
    v-if="showHead"
    :style="[0, 1, 9, 10, 11].includes(messageType) && 'margin-right: 3.2rem'"
    class="message__mail-head"
  >
    <div class="flex-row flex-align--start flex-justify--between">
      <div class="profile flex-row">
        <thumbnail
          v-if="isCsat || [3, 9].includes(messageType)"
          :src="'https://ik.imagekit.io/limechatai/Ellipse_2-3_j9UWmYtQ6.png'"
          bg-color="#6bac1b"
          color="#fff"
          size="24px"
          :username="senderAddress"
        />
        <thumbnail
          v-else
          :src="sender.avatar_url"
          :bg-color="thumbnailBgColor"
          color="#fff"
          size="24px"
          :username="senderAddress"
        />
        <span class="title-h5_m flex-center">
          <account-pii-mask-wrapper
            :pii-data="senderAddress"
            :mask-type="maskType"
            :mask-state="maskState"
            toggle-icon-size="normal"
          >
            <template v-slot="{ data }">
              {{ data }}
            </template>
          </account-pii-mask-wrapper>
          <span v-if="isForwarded" class="body-b3 text-light forwarded-text">
            {{ $t('EMAIL_HEADER.FORWARDED') }}
          </span>
          <span
            v-if="!isFirstEmail && !isForwarded && [0, 1].includes(messageType)"
            class="body-b3 text-light forwarded-text"
          >
            {{ $t('EMAIL_HEADER.REPLIED') }}
          </span>
        </span>
      </div>
      <span
        v-if="emailAttributes.date || replyTimeStamp"
        class="text-light body-b3 line-clamp-1"
        v-text="timeStamp"
      />
    </div>
    <div class="meta-wrap">
      <span v-if="toMails && !isForwarded" class="text-light body-b3 flex-row">
        {{ $t('EMAIL_HEADER.TO') + ':\u00A0' }}
        <account-pii-mask-wrapper
          :pii-data="toMails"
          :mask-state="maskState"
          toggle-icon-size="normal"
        >
          <template v-slot="{ data }">
            {{ data }}
          </template>
        </account-pii-mask-wrapper>
      </span>
      <span v-if="toMails && isForwarded" class="text-light body-b3 flex-row">
        {{ $t('EMAIL_HEADER.FORWARDED_TO') + ':\u00A0' }}
        <account-pii-mask-wrapper
          :pii-data="toMails"
          :mask-state="maskState"
          toggle-icon-size="normal"
        >
          <template v-slot="{ data }">
            {{ data }}
          </template>
        </account-pii-mask-wrapper>
      </span>
      <span v-if="ccMails" class="text-light body-b3 flex-row">
        | {{ $t('EMAIL_HEADER.CC') + ':\u00A0' }}
        <account-pii-mask-wrapper
          :pii-data="ccMails"
          :mask-state="maskState"
          toggle-icon-size="normal"
        >
          <template v-slot="{ data }">
            {{ data }}
          </template>
        </account-pii-mask-wrapper>
      </span>
      <span v-if="bccMails" class="text-light body-b3 flex-row">
        | {{ $t('EMAIL_HEADER.BCC') + ':\u00A0' }}
        <account-pii-mask-wrapper :pii-data="bccMails" :mask-state="maskState">
          <template v-slot="{ data }">
            {{ data }}
          </template>
        </account-pii-mask-wrapper>
      </span>
    </div>
  </div>
</template>

<script>
import Thumbnail from '../../../widgets/Thumbnail.vue';
import AccountPiiMaskWrapper from 'dashboard/components/widgets/piiMask/AccountPiiMaskWrapper.vue';

import { humanReadableDate } from 'dashboard/helper/time';
import timeMixin from '../../../../mixins/time';
import { MASK_STATE, MASK_TYPES } from '../../piiMask/constants';

const OPTIONS = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export default {
  components: { Thumbnail, AccountPiiMaskWrapper },
  mixins: [timeMixin],
  props: {
    recipient: {
      type: String,
      default: '',
    },
    contentType: {
      type: String,
      default: '',
    },
    messageType: {
      type: Number,
      default: -1,
    },
    replyTimeStamp: {
      type: Number,
      default: 0,
    },
    isFirstEmail: {
      type: Boolean,
      default: false,
    },
    cc: {
      type: Array,
      default: () => [],
    },
    bcc: {
      type: Array,
      default: () => [],
    },
    sender: {
      type: Object,
      default: () => {},
    },
    emailAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    maskState() {
      return MASK_STATE.clientMask;
    },
    maskType() {
      return MASK_TYPES.EMAIL;
    },
    isCsat() {
      return this.messageType === 1 && this.contentType === 'input_csat';
    },
    thumbnailBgColor() {
      if ([1, 10].includes(this.messageType)) return '#6bac1b';
      return '#f6bd16';
    },
    senderAddress() {
      if (this.messageType === 0 && this.emailAttributes.from)
        return this.emailAttributes.from[0];

      if ([3, 9].includes(this.messageType))
        return this.$t('EMAIL_HEADER.AUTOMATED_REPLY');

      if (this.isCsat) return this.$t('EMAIL_HEADER.AUTOMATED_REPLY');

      return this.sender.name || this.sender.email;
    },
    isForwarded() {
      return this.messageType === 10;
    },
    toMails() {
      const to = this.emailAttributes.to || [this.recipient] || [];
      return to.join(', ');
    },
    ccMails() {
      const cc = this.emailAttributes.cc || this.cc || [];
      return cc.join(', ');
    },
    bccMails() {
      const bcc = this.emailAttributes.bcc || this.bcc || [];
      return bcc.join(', ');
    },
    showHead() {
      return this.recipient || this.toMails || this.ccMails || this.bccMails;
    },
    timeStamp() {
      const date = new Date(
        this.emailAttributes.date || this.replyTimeStamp * 1000
      );

      const formattedDate = humanReadableDate(date, OPTIONS);

      const distanceTime = this.dynamicTime(
        date.getTime() / 1000 || this.replyTimeStamp
      );

      return `${formattedDate}\u00A0\u00A0(${distanceTime})`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.message__mail-head {
  padding-bottom: $space-small;
  margin-bottom: $space-small;
  width: 100%;

  .profile {
    gap: $space-small;
    margin-bottom: $space-small;
    .forwarded-text {
      margin-left: $space-smaller;
    }
  }
}

.meta-wrap {
  padding: 0 $space-large;

  span {
    font-size: var(--font-size-mini);
  }
}
</style>
