export default {
  AUTHENTICATE: 'AUTHENTICATE',
  CLEAR_USER: 'LOGOUT',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_CURRENT_ACCOUNT_ID: 'SET_CURRENT_ACCOUNT_ID',
  SET_CURRENT_USER_AVAILABILITY: 'SET_CURRENT_USER_AVAILABILITY',
  SET_CURRENT_USER_UI_SETTINGS: 'SET_CURRENT_USER_UI_SETTINGS',

  // Chat List
  RECEIVE_CHAT_LIST: 'RECEIVE_CHAT_LIST',
  SET_ALL_CONVERSATION: 'SET_ALL_CONVERSATION',
  SET_ALL_TAB_CONVERSATION: 'SET_ALL_TAB_CONVERSATION',
  EMPTY_ALL_TAB_CONVERSATION: 'EMPTY_ALL_TAB_CONVERSATION',
  SET_CONV_TAB_META: 'SET_CONV_TAB_META',
  UPDATE_CONVERSATION_STATS_ON_STATUS_CHANGE:
    'UPDATE_CONVERSATION_STATS_ON_STATUS_CHANGE',
  UPDATE_CONVERSATION_STATS_ON_ASSIGNEE_CHANGE:
    'UPDATE_CONVERSATION_STATS_ON_ASSIGNEE_CHANGE',
  CLEAR_LIST_LOADING_STATUS: 'CLEAR_LIST_LOADING_STATUS',
  SET_LIST_LOADING_STATUS: 'SET_LIST_LOADING_STATUS',
  SET_ALL_MESSAGES_LOADED: 'SET_ALL_MESSAGES_LOADED',
  CLEAR_ALL_MESSAGES_LOADED: 'CLEAR_ALL_MESSAGES_LOADED',
  CHANGE_CHAT_STATUS: 'CHANGE_CHAT_STATUS',
  UPDATE_ASSIGNEE: 'UPDATE_ASSIGNEE',
  UPDATE_CONVERSATION_CONTACT: 'UPDATE_CONVERSATION_CONTACT',
  SET_CONVERSATION_FILTERS: 'SET_CONVERSATION_FILTERS',
  RESET_CONVERSATION_FILTERS: 'RESET_CONVERSATION_FILTERS',
  UPDATE_CONVERSATION_FILTERS: 'UPDATE_CONVERSATION_FILTERS',
  UPDATE_ALL_CONVERSATION_PROPERTY: 'UPDATE_ALL_CONVERSATION_PROPERTY',
  UPDATE_ALL_TAB_CONVERSATION_PROPERTY: 'UPDATE_ALL_TAB_CONVERSATION_PROPERTY',
  SET_CONVERSATION_UI_FLAGS: 'SET_CONVERSATION_UI_FLAGS',

  SET_CURRENT_CHAT_WINDOW: 'SET_CURRENT_CHAT_WINDOW',
  CLEAR_CURRENT_CHAT_WINDOW: 'CLEAR_CURRENT_CHAT_WINDOW',
  CLEAR_ALL_MESSAGES: 'CLEAR_ALL_MESSAGES',
  RESOLVE_CONVERSATION: 'RESOLVE_CONVERSATION',
  BULK_RESOLVE_CONVERSATION: 'BULK_RESOLVE_CONVERSATION',
  ADD_CONVERSATION: 'ADD_CONVERSATION',
  ADD_CONVERSATION_TO_ALL_TAB: 'ADD_CONVERSATION_TO_ALL_TAB',
  UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
  UPDATE_ALL_TAB_CONVERSATION: 'UPDATE_ALL_TAB_CONVERSATION',
  MUTE_CONVERSATION: 'MUTE_CONVERSATION',
  UNMUTE_CONVERSATION: 'UNMUTE_CONVERSATION',
  ASSIGN_AGENT: 'ASSIGN_AGENT',
  ASSIGN_TEAM: 'ASSIGN_TEAM',
  BULK_ASSIGN_AGENT: 'BULK_ASSIGN_AGENT',
  BULK_ASSIGN_TEAM: 'BULK_ASSIGN_TEAM',
  SET_CHAT_META: 'SET_CHAT_META',
  SET_UNREAD_COUNT: 'SET_UNREAD_COUNT',
  ADD_MESSAGE: 'ADD_MESSAGE',
  UPDATE_MESSAGE_TIMESTAMP_IN_ALL_TAB_CONVERSATION:
    'UPDATE_MESSAGE_TIMESTAMP_IN_ALL_TAB_CONVERSATION',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  STAR_CONVERSATION: 'STAR_CONVERSATION',
  BULK_STAR_CONVERSATION: 'BULK_STAR_CONVERSATION',
  SET_BULK_CONVERSATION_LABELS: 'SET_BULK_CONVERSATION_LABELS',
  ADD_PENDING_MESSAGE: 'ADD_PENDING_MESSAGE',
  REMOVE_PENDING_MESSAGE: 'REMOVE_PENDING_MESSAGE',
  MARK_MESSAGE_READ: 'MARK_MESSAGE_READ',
  SET_PREVIOUS_CONVERSATIONS: 'SET_PREVIOUS_CONVERSATIONS',
  UPDATE_PENDING_MESSAGES: 'UPDATE_PENDING_MESSAGES',
  SET_ACTIVE_INBOX: 'SET_ACTIVE_INBOX',

  SET_CONVERSATION_CAN_REPLY: 'SET_CONVERSATION_CAN_REPLY',
  UPDATE_CONVERSATION_NEW_LABEL: 'UPDATE_CONVERSATION_NEW_LABEL',

  // Inboxes
  SET_INBOXES_UI_FLAG: 'SET_INBOXES_UI_FLAG',
  SET_INBOXES: 'SET_INBOXES',
  SET_INBOX_ITEM: 'SET_INBOX_ITEM',
  ADD_INBOXES: 'ADD_INBOXES',
  EDIT_INBOXES: 'EDIT_INBOXES',
  EDIT_INBOXES_WITHOUT_OVERRIDING: 'EDIT_INBOXES_WITHOUT_OVERRIDING',
  DELETE_INBOXES: 'DELETE_INBOXES',

  // Account
  SET_ACCOUNT_UI_FLAG: 'SET_ACCOUNT_UI_FLAG',
  SET_ACCOUNTS: 'SET_ACCOUNTS',
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  EDIT_ACCOUNT_WITHOUT_OVERRIDING: 'EDIT_ACCOUNT_WITHOUT_OVERRIDING',
  DELETE_ACCOUNT: 'DELETE_AGENT',

  // Agent
  SET_AGENT_FETCHING_STATUS: 'SET_AGENT_FETCHING_STATUS',
  SET_AGENT_CREATING_STATUS: 'SET_AGENT_CREATING_STATUS',
  SET_AGENT_UPDATING_STATUS: 'SET_AGENT_UPDATING_STATUS',
  SET_AGENT_DELETING_STATUS: 'SET_AGENT_DELETING_STATUS',
  SET_AGENTS: 'SET_AGENTS',
  ADD_AGENT: 'ADD_AGENT',
  EDIT_AGENT: 'EDIT_AGENT',
  DELETE_AGENT: 'DELETE_AGENT',
  DELETE_AGENTS: 'DELETE_AGENTS',
  UPDATE_AGENTS_PRESENCE: 'UPDATE_AGENTS_PRESENCE',
  SET_CURRENT_AGENT_TICKETS_META: 'SET_CURRENT_AGENT_TICKETS_META',

  // Canned Response
  SET_CANNED_UI_FLAG: 'SET_CANNED_UI_FLAG',
  SET_CANNED: 'SET_CANNED',
  ADD_CANNED: 'ADD_CANNED',
  EDIT_CANNED: 'EDIT_CANNED',
  DELETE_CANNED: 'DELETE_CANNED',

  // Templates
  SET_TEMPLATE_UI_FLAG: 'SET_TEMPLATE_UI_FLAG',
  SET_TEMPLATE: 'SET_TEMPLATE',
  ADD_TEMPLATE: 'ADD_TEMPLATE',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',

  // Automations
  SET_AUTOMATION_UI_FLAG: 'SET_AUTOMATION_UI_FLAG',
  SET_AUTOMATIONS: 'SET_AUTOMATIONS',
  SET_AUTOMATION_TEMPLATES: 'SET_AUTOMATION_TEMPLATES',
  ADD_AUTOMATION: 'ADD_AUTOMATION',
  EDIT_AUTOMATION: 'EDIT_AUTOMATION',
  DELETE_AUTOMATION: 'DELETE_AUTOMATION',

  /* Custom Ticket Fields */
  SET_CUSTOM_TICKET_FIELDS_UI_FLAG: 'SET_CUSTOM_TICKET_FIELDS_UI_FLAG',
  SET_CUSTOM_TICKET_FIELDS_LIST: 'SET_CUSTOM_TICKET_FIELDS_LIST',
  REMOVE_CUSTOM_TICKET_FIELD: 'REMOVE_CUSTOM_TICKET_FIELD',
  SET_CUSTOM_TICKET_FIELD_BY_ID: 'SET_CUSTOM_TICKET_FIELD_BY_ID',
  ADD_CUSTOM_TICKET_FIELD: 'ADD_CUSTOM_TICKET_FIELD',
  TOGGLE_CUSTOM_TICKET_FIELD_STATUS: 'TOGGLE_CUSTOM_TICKET_FIELD_STATUS',

  // CSAT Responses
  SET_CSAT_RESPONSE_UI_FLAG: 'SET_CSAT_RESPONSE_UI_FLAG',
  SET_CSAT_RESPONSE: 'SET_CSAT_RESPONSE',
  SET_CSAT_RESPONSE_METRICS: 'SET_CSAT_RESPONSE_METRICS',
  SET_BOT_CSAT_RESPONSE_METRICS: 'SET_BOT_CSAT_RESPONSE_METRICS',

  // Broadcast
  SET_BROADCAST_LIST: 'SET_BROADCAST_LIST',
  SET_BROADCAST_PROGRESS: 'SET_BROADCAST_PROGRESS',
  CANCEL_BROADCAST: 'CANCEL_BROADCAST',

  // Labels
  SET_LABEL_UI_FLAG: 'SET_LABEL_UI_FLAG',
  SET_LABELS: 'SET_LABELS',
  SET_LABELS_WITHOUT_GROUPS: 'SET_LABELS_WITHOUT_GROUPS',
  ADD_LABEL: 'ADD_LABEL',
  EDIT_LABEL: 'EDIT_LABEL',
  UPDATE_STATUSES: 'UPDATE_STATUSES',
  DELETE_LABEL: 'DELETE_LABEL',

  // Integrations
  SET_INTEGRATIONS_UI_FLAG: 'SET_INTEGRATIONS_UI_FLAG',
  SET_INTEGRATIONS: 'SET_INTEGRATIONS',
  ADD_INTEGRATION: 'ADD_INTEGRATION',
  DELETE_INTEGRATION: 'DELETE_INTEGRATION',

  // Store notifications
  SET_STORE_NOTIFICATIONS_UI_FLAG: 'SET_STORE_NOTIFICATIONS_UI_FLAG',

  // WebHook
  SET_WEBHOOK_UI_FLAG: 'SET_WEBHOOK_UI_FLAG',
  SET_WEBHOOK: 'SET_WEBHOOK',
  ADD_WEBHOOK: 'ADD_WEBHOOK',
  UPDATE_WEBHOOK: 'UPDATE_WEBHOOK',
  DELETE_WEBHOOK: 'DELETE_WEBHOOK',

  // Contacts
  SET_CONTACT_META: 'SET_CONTACT_META',
  SET_CONTACT_UI_FLAG: 'SET_CONTACT_UI_FLAG',
  SET_CONTACT_ITEM: 'SET_CONTACT_ITEM',
  SET_CONTACTS: 'SET_CONTACTS',
  SET_SEARCHED_CONTACTS: 'SET_SEARCHED_CONTACTS',
  SET_OPT_OUT_CONTACTS: 'SET_OPT_OUT_CONTACTS',
  CLEAR_CONTACTS: 'CLEAR_CONTACTS',
  CLEAR_SEARCHED_CONTACTS: 'CLEAR_SEARCHED_CONTACTS',
  EDIT_CONTACT: 'EDIT_CONTACT',
  UPDATE_CONTACTS_PRESENCE: 'UPDATE_CONTACTS_PRESENCE',
  SET_CONTACT_LABELS: 'SET_CONTACT_LABELS',
  SET_CONTACT_LABELS_UI_FLAG: 'SET_CONTACT_LABELS_UI_FLAG',

  // Notifications
  SET_NOTIFICATIONS_META: 'SET_NOTIFICATIONS_META',
  SET_NOTIFICATIONS_UNREAD_COUNT: 'SET_NOTIFICATIONS_UNREAD_COUNT',
  SET_NOTIFICATIONS_UI_FLAG: 'SET_NOTIFICATIONS_UI_FLAG',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  UPDATE_ALL_NOTIFICATIONS: 'UPDATE_ALL_NOTIFICATIONS',
  SET_NOTIFICATIONS_ITEM: 'SET_NOTIFICATIONS_ITEM',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  EDIT_NOTIFICATIONS: 'EDIT_NOTIFICATIONS',
  UPDATE_NOTIFICATIONS_PRESENCE: 'UPDATE_NOTIFICATIONS_PRESENCE',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',

  // Contact Conversation
  SET_CONTACT_CONVERSATIONS_UI_FLAG: 'SET_CONTACT_CONVERSATIONS_UI_FLAG',
  SET_CONTACT_CONVERSATIONS: 'SET_CONTACT_CONVERSATIONS',

  // Conversation Label
  SET_CONVERSATION_LABELS_UI_FLAG: 'SET_CONVERSATION_LABELS_UI_FLAG',
  SET_CONVERSATION_LABELS: 'SET_CONVERSATION_LABELS',

  // Reports
  SET_ACCOUNT_REPORTS: 'SET_ACCOUNT_REPORTS',
  SET_ACCOUNT_SUMMARY: 'SET_ACCOUNT_SUMMARY',
  TOGGLE_ACCOUNT_REPORT_LOADING: 'TOGGLE_ACCOUNT_REPORT_LOADING',

  // Conversation Metadata
  SET_CONVERSATION_METADATA: 'SET_CONVERSATION_METADATA',

  // Conversation Page
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_CONVERSATION_END_REACHED: 'SET_CONVERSATION_END_REACHED',
  CLEAR_CONVERSATION_PAGE: 'CLEAR_CONVERSATION_PAGE',

  // Notification Settings
  SET_USER_NOTIFICATION_UI_FLAG: 'SET_USER_NOTIFICATION_UI_FLAG',
  SET_USER_NOTIFICATION: 'SET_USER_NOTIFICATION',

  // User Typing
  ADD_USER_TYPING_TO_CONVERSATION: 'ADD_USER_TYPING_TO_CONVERSATION',
  REMOVE_USER_TYPING_FROM_CONVERSATION: 'REMOVE_USER_TYPING_FROM_CONVERSATION',

  // Billing Plans
  SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
  TOGGLE_SUBSCRIPTION_LOADING: 'TOGGLE_SUBSCRIPTION_LOADING',

  // ChatApi Status
  INBOXSTATUS_CHANGED: 'INBOXSTATUS_CHANGED',

  // Conversation Search
  SEARCH_CONVERSATIONS_SET: 'SEARCH_CONVERSATIONS_SET',
  SEARCH_CONVERSATIONS_RESET: 'SEARCH_CONVERSATIONS_RESET',
  SAVE_SEARCH_CONVERSATIONS: 'SAVE_SEARCH_CONVERSATIONS',
  CLEAR_SAVE_SEARCH_CONVERSATIONS: 'CLEAR_SAVE_SEARCH_CONVERSATIONS',
  SEARCH_CONVERSATIONS_APPEND: 'SEARCH_CONVERSATIONS_APPEND',
  SEARCH_CONVERSATIONS_SET_UI_FLAG: 'SEARCH_CONVERSATIONS_SET_UI_FLAG',
  APPEND_SEARCH_CONVERSATIONS_RECORD: 'APPEND_SEARCH_CONVERSATIONS_RECORD',
  SAVE_SEARCH_CONVERSATIONS_REQUEST_PAYLOAD:
    'SAVE_SEARCH_CONVERSATIONS_REQUEST_PAYLOAD',
  CLEAR_SEARCH_CONVERSATIONS_REQUEST_PAYLOAD:
    'CLEAR_SEARCH_CONVERSATIONS_REQUEST_PAYLOAD',

  // Orders
  SET_ORDERS_UI_FLAG: 'SET_ORDERS_UI_FLAG',
  SET_DRAFT_ORDER_UI_FLAG: 'SET_DRAFT_ORDER_UI_FLAG',
  SET_ORDERS: 'SET_ORDERS',
  RESET_ORDERS: 'RESET_ORDERS',
  SET_ACTIVE_SHOPIFY_ORDER: 'SET_ACTIVE_SHOPIFY_ORDER',
  SET_DRAFT_ORDER: 'SET_DRAFT_ORDER',
  RESET_DRAFT_ORDER: 'RESET_DRAFT_ORDER',
  SET_ORDER_MODE: 'SET_ORDER_MODE',
  SET_COD_ORDER_UI_FLAG: 'SET_COD_ORDER_UI_FLAG',
  EMPTY_ORDERS: 'EMPTY_ORDERS',
  SET_ACCOUNT_SHIPPING_RATES: 'SET_ACCOUNT_SHIPPING_RATES',

  // Products
  SET_PRODUCTS_UI_FLAG: 'SET_PRODUCTS_UI_FLAG',
  SET_PRODUCTS: 'SET_PRODUCTS',
  UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',

  // Shopify - Cart
  SET_CART: 'SET_CART',
  EMPTY_CART: 'EMPTY_CART',
  ADD_PRODUCT_IN_CART: 'ADD_PRODUCT_IN_CART',
  REMOVE_PRODUCT_FROM_CART: 'REMOVE_PRODUCT_FROM_CART',
  UPDATE_PRODUCT_IN_CART: 'UPDATE_PRODUCT_IN_CART',
  SET_SHOPIFY_ORDERS: 'SET_SHOPIFY_ORDERS',

  // Shopify - Customer
  SET_EDIT_MODE_CART: 'SET_EDIT_MODE_CART',
  SET_CUSTOMER_DETAILS: 'SET_CUSTOMER_DETAILS',
  ADD_NEW_CUSTOMER_ADDRESS: 'ADD_NEW_CUSTOMER_ADDRESS',
  UPDATE_CUSTOMER_ADDRESS: 'UPDATE_CUSTOMER_ADDRESS',
  SET_SELETED_ADDRESS: 'SET_SELETED_ADDRESS',
  DELETE_CUSTOMER_ADDRESS: 'DELETE_CUSTOMER_ADDRESS',
  EMPTY_CUSTOMER_DETAILS: 'EMPTY_CUSTOMER_DETAILS',
  ADD_ADDRESS_UI_FLAG: 'ADD_ADDRESS_UI_FLAG',

  // CRM Ticketing.
  SET_ENABLED_CRMS: 'SET_ENABLED_CRMS',
  SET_DEPARTMENT_NAMES: 'SET_ZOHODESK_DEPARTMENT_NAMES',
  SET_TICKETS_UI_FLAG: 'SET_TICKETS_UI_FLAG',
  SET_TICKETS: 'SET_TICKETS',
  EDIT_TICKET: 'EDIT_TICKET',
  DELETE_TICKET: 'DELETE_TICKET',
  SET_SELECTED_CRM: 'SET_SELECTED_CRM',
  SET_TICKET_FORM_SCHEMA: 'SET_TICKET_FORM_SCHEMA',
  SET_JIRA_PROJECT_AND_ISSUE_TYPES: 'SET_JIRA_PROJECT_AND_ISSUE_TYPES',

  // Logistics
  SET_ENABLED_LOGISTICS: 'SET_ENABLED_LOGISTICS',

  // Attendance
  SET_ATTENDANCE_METRICS: 'SET_ATTENDANCE_METRICS',
  SET_ATTENDANCE_LOGS: 'SET_ATTENDANCE_LOGS',
  SET_ANALYTICS_UI_FLAG: 'SET_ANALYTICS_UI_FLAG',

  // Performance
  SET_AGENT_METRICS_UI_FLAG: 'SET_AGENT_METRICS_UI_FLAG',
  SET_AGENT_PERIODIC_UI_FLAG: 'SET_AGENT_PERIODIC_UI_FLAG',
  SET_AGENT_MEAN_UI_FLAG: 'SET_AGENT_MEAN_UI_FLAG',
  SET_AGENT_HOURLY_UI_FLAG: 'SET_AGENT_HOURLY_UI_FLAG',
  SET_AGENT_MSG_BLOCK_UI_FLAG: 'SET_AGENT_MSG_BLOCK_UI_FLAG',
  SET_AGENT_CONV_STAT_UI_FLAG: 'SET_AGENT_CONV_STAT_UI_FLAG',

  // Conversation
  SET_CONVERSATION_METRICS_UI_FLAG: 'SET_CONVERSATION_METRICS_UI_FLAG',
  SET_CONVERSATION_STATE_UI_FLAG: 'SET_CONVERSATION_STATE_UI_FLAG',
  SET_CONVERSATION_HANDOFF_TREND_UI_FLAG:
    'SET_CONVERSATION_HANDOFF_TREND_UI_FLAG',

  // Bot
  SET_BOT_CARDS_UI_FLAG: 'SET_BOT_CARDS_UI_FLAG',
  SET_BOT_LINE_CHART_UI_FLAG: ' SET_BOT_LINE_CHART_UI_FLAG',
  SET_BOT_ANALYTICS_UI_FLAG: 'SET_BOT_ANALYTICS_UI_FLAG',

  // Sales
  SET_SALES_ANALYTICS_UI_FLAG: 'SET_SALES_ANALYTICS_UI_FLAG',
  SET_SALES_TABLE_UI_FLAG: 'SET_SALES_TABLE_UI_FLAG',

  // Users
  SET_USERS_INBOX_WEBSITE_UI_FLAG: 'SET_USERS_INBOX_WEBSITE_UI_FLAG',
  SET_USERS_CONCERNS_COMPLAIN_UI_FLAG: 'SET_USERS_CONCERNS_COMPLAIN_UI_FLAG',
  SET_USERS_HANDOFF_UI_FLAG: 'SET_USERS_HANDOFF_UI_FLAG',
  SET_USERS_ACTIVITY_UI_FLAG: 'SET_USERS_ACTIVITY_UI_FLAG',
  SET_BOT_FAILING_UI_FLAG: 'SET_BOT_FAILING_UI_FLAG',
  SET_USERS_DOWNLOAD_TABLE_UI_FLAG: 'SET_USERA_DOWNLOAD_TABLE_UI_FLAG',

  // Shopify
  SET_ACCOUNT_SHOPIFY_STATUS: 'SET_ACCOUNT_SHOPIFY_STATUS',

  // knowlarity
  SET_CALL_POPUP_FLAG: 'SET_CALL_POPUP_FLAG',
  SET_CALL_INFORMATION: 'SET_CALL_INFORMATION',
  UPDATE_CALL_STATE: 'UPDATE_CALL_STATE',
  RESET_CALL_STATE: 'RESET_CALL_STATE',
  SET_CALL_INITIATION_UI_FLAG: 'SET_CALL_INITIATION_UI_FLAG',
  CALL_FAILURE_UI_FLAG: 'CALL_FAILURE_UI_FLAG',
  SET_CALL_NOTIFICATION_FLAG: 'SET_CALL_NOTIFICATION_FLAG',
  SET_CALL_META: 'SET_CALL_META',
  SET_KNOWLARITY_AGENTS: 'SET_KNOWLARITY_AGENTS',

  // Dialog 360
  SET_DIALOG_UI_FLAG: 'SET_DIALOG_UI_FLAG',
  SET_PARTNER_ID: 'SET_PARTNER_ID',

  // Set ui element visibility
  SET_UI_VISIBILITY_FLAGS: 'SET_UI_VISIBILITY_FLAGS',

  // Onboarding
  SHOW_ONBOARDING_MODAL: 'SHOW_ONBOARDING_MODAL',
  SET_CURRENT_SCREEN: 'SET_CURRENT_SCREEN',

  // intelligence
  IMPROVING_TEXT_UI_FLAG: 'IMPROVING_TEXT_UI_FLAG',

  // deep shopify analytics
  SET_DEEP_SHOPIFY_ANALYTICS_UI_FLAGS: 'SET_DEEP_SHOPIFY_ANALYTICS_UI_FLAGS',
  SET_DEEP_SHOPIFY_ANALYTICS_METRICS: 'SET_DEEP_SHOPIFY_ANALYTICS_METRICS',
  SET_DEEP_SHOPIFY_ANALYTICS_TOP_ORDERED_PRODUCTS:
    'SET_DEEP_SHOPIFY_ANALYTICS_TOP_ORDERED_PRODUCTS',
  SET_DEEP_SHOPIFY_ANALYTICS_TOP_CANCELLED_PRODUCTS:
    'SET_DEEP_SHOPIFY_ANALYTICS_TOP_CANCELLED_PRODUCTS',
  SET_DEEP_SHOPIFY_ANALYTICS_PERIODIC_ORDER_COUNT:
    'SET_DEEP_SHOPIFY_ANALYTICS_PERIODIC_ORDER_COUNT',
  SET_DEEP_SHOPIFY_ANALYTICS_COD_PREPAID_SPLIT:
    'SET_DEEP_SHOPIFY_ANALYTICS_COD_PREPAID_SPLIT',
  SET_DEEP_SHOPIFY_ORDERS: 'SET_DEEP_SHOPIFY_ORDERS',
  SET_DEEP_SHOPIFY_ANALYTICS_ORDER_STATUSES:
    'SET_DEEP_SHOPIFY_ANALYTICS_ORDER_STATUSES',

  // conversation views
  SET_CONV_VIEWS_UI_FLAGS: 'SET_CONV_VIEWS_UI_FLAGS',
  SET_CUSTOM_CONV_VIEWS: 'SET_CUSTOM_CONV_VIEWS',
  SET_CURATED_CONV_VIEWS: 'SET_CURATED_CONV_VIEWS',
  SET_DEFAULT_CONV_VIEW: 'SET_DEFAULT_CONV_VIEW',
  SET_CURRENT_CONV_VIEW: 'SET_CURRENT_CONV_VIEW',
  ADD_CUSTOM_CONV_VIEW: 'ADD_CUSTOM_CONV_VIEW',
  UPDATE_CUSTOM_CONV_VIEW: 'UPDATE_CUSTOM_CONV_VIEW',
  DELETE_CONV_VIEW: 'DELETE_CONV_VIEW',
  SET_CURRENT_VIEW_FILTERS: 'SET_CURRENT_VIEW_FILTERS',
  UPDATE_CURRENT_VIEW_FILTERS: 'UPDATE_CURRENT_VIEW_FILTERS',
  RESET_CURRENT_VIEW_FILTERS: 'RESET_CURRENT_VIEW_FILTERS',

  // SLA
  SET_SLAS: 'SET_SLAS',
  ADD_SLA: 'ADD_SLA',
  UPDATE_SLA: 'UPDATE_SLA',
  REMOVE_SLA: 'REMOVE_SLA',
  SET_SLA_UI_FLAGS: 'SET_SLA_UI_FLAGS',

  // PII-MASK
  SET_PII_UI_FLAG: 'SET_PII_UI_FLAG',
  LOG_PII_UNMASK_ACTION: 'LOG_PII_UNMASK_ACTION',
};
