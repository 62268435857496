import Vue from 'vue';
import Vuex from 'vuex';

import accounts from './modules/accounts';
import agents from './modules/agents';
import auth from './modules/auth';
import cannedResponse from './modules/cannedResponse';
import contactConversations from './modules/contactConversations';
import contacts from './modules/contacts';
import notifications from './modules/notifications';
import conversationLabels from './modules/conversationLabels';
import contactLabels from './modules/contactLabels';
import conversationMetadata from './modules/conversationMetadata';
import conversationPage from './modules/conversationPage';
import conversations from './modules/conversations';
import conversationViews from './modules/conversationViews';
import limekit from './modules/limekit';
import conversationSearch from './modules/conversationSearch';
import conversationStats from './modules/conversationStats';
import conversationTypingStatus from './modules/conversationTypingStatus';
import globalConfig from 'shared/store/globalConfig';
import inboxes from './modules/inboxes';
import inboxMembers from './modules/inboxMembers';
import integrations from './modules/integrations';
import labels from './modules/labels';
import logistics from './modules/logistics';
import reports from './modules/reports';
import userNotificationSettings from './modules/userNotificationSettings';
import webhooks from './modules/webhooks';
import billingPlan from './modules/billingPlan';
import template from './modules/template';
import storeNotifications from './modules/storeNotifications';
import crm from './modules/crm';
import teams from './modules/teams';
import teamMembers from './modules/teamMembers';
import csat from './modules/csat';
import knowlarity from './modules/knowlarity';
import automations from './modules/automations';
import customTicketFields from './modules/customTicketFields';
import threeSixtyDialog from './modules/threeSixtyDialog';
import intelligence from './modules/intelligence';
import captureFeedback from './modules/captureFeedback';
import deepShopifyAnalytics from './modules/deepShopifyAnalytics';
import sla from './modules/sla';
import piiMask from './modules/piiMask';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    accounts,
    agents,
    auth,
    automations,
    cannedResponse,
    contactConversations,
    contacts,
    notifications,
    conversationLabels,
    contactLabels,
    conversationMetadata,
    conversationPage,
    conversations,
    conversationViews,
    conversationSearch,
    conversationStats,
    conversationTypingStatus,
    csat,
    customTicketFields,
    globalConfig,
    inboxes,
    inboxMembers,
    integrations,
    labels,
    logistics,
    reports,
    userNotificationSettings,
    webhooks,
    billingPlan,
    limekit,
    template,
    storeNotifications,
    crm,
    teams,
    teamMembers,
    knowlarity,
    threeSixtyDialog,
    intelligence,
    captureFeedback,
    deepShopifyAnalytics,
    sla,
    piiMask,
  },
});
